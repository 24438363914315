<template>
    <div class="billManger-container">
        <CSTabBar :tabbar="tabBar"
                  :checked-tab="checkedTaBarNumer"
                  @changeTabBar="changeTabBar">

        </CSTabBar>
        <template v-if="checkedTaBarNumer == 1">
            <ChargeWater></ChargeWater>
        </template>
        <template v-if="checkedTaBarNumer == 2">
            <ElectricityFees></ElectricityFees>
        </template>
    </div>
</template>

<script>
    import CSTabBar from "@/components/common/CSTabBar";
    import ChargeWater from './chargeWater';
    import ElectricityFees from './electricityFees'
    export default {
        created() {
            console.log(this.$route.params);
            if(this.$route.params.checkedTaBard){
                this.checkedTaBarNumer = this.$route.params.checkedTaBard;
            }
        },
        name: "BillManger.vue",
        components:{
            CSTabBar,
            ChargeWater,
            ElectricityFees
        },
        data(){
            return{
                tabBar:{
                    1:'水费',
                    2:'电费'
                },
                checkedTaBarNumer:1
            }
        },
        methods:{
            changeTabBar(index){
                this.checkedTaBarNumer = index;
            }
        }
    }
</script>

<style scoped>

</style>
