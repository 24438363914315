<template>
    <div class="poolBill-container">
        <!-- 头部选择框 -->
        <CSTabBar
                :tabbar="tabBar"
                :checked-tab="checkedTaBarNumer"
                @changeTabBar="changeTabBar"
        />
        <router-view></router-view>
    </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import BillManage from "@/views/WaterElectricityMeter/PoolBill/Bill/BillManger";
import SummaryManager from "@/views/WaterElectricityMeter/PoolBill/Summary/SummaryManager";

export default {
        name: "PoolBill.vue",
        components: {
            CSTabBar,
            BillManage,
            SummaryManager,
        },
        data() {
            return {
                tabBar: {
                    1: "公摊汇总",
                    2: "公摊账单"
                },
                checkedTaBarNumer: 1
            }
        },
        created() {
            console.log('this.$route.params.checkedTaBarNumer');
            console.log(this.$route.params.checkedTaBarNumer);
            console.log(this.$route);
            if(this.$route.meta.checkedTaBarNumer){
                this.checkedTaBarNumer = this.$route.meta.checkedTaBarNumer;
            }
            if (this.$route.params.checkedTaBarNumer) {
                this.checkedTaBarNumer = this.$route.params.checkedTaBarNumer;
            }

        },
        methods: {
            //状态选择按钮
            changeTabBar(index) {
                this.checkedTaBarNumer = index;
                if (index == 1) {
                    this.$router.push({
                        name: 'summaryManager',
                        params: {
                            checkedTaBarNumer: this.checkedTaBarNumer
                        }
                    })

                } else {
                    this.$router.push({
                        name: 'billManger',
                        params: {
                            checkedTaBarNumer: this.checkedTaBarNumer
                        }
                    })
                }
            },
        }
    }
</script>

<style scoped>

</style>
