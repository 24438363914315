<template>
  <div class="bill-water-container">
    <!-- 条件查询框 -->
    <div class="filter-panel">
      <CSSelect i-width="36rpx" style="width: 230px; margin-right: 0">
        <el-date-picker
            v-model="conditions.startDate"
            :editable="false"
            format="yyyy-MM-dd HH:mm"
            placeholder="请选择开始时间"
            prefix-icon="el-icon-time"
            style="width: 214px"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>
      </CSSelect>
      <span class="inline-gray"> </span>
      <CSSelect i-width="36rpx" style="width: 230px">
        <el-date-picker
            v-model="conditions.endDate"
            :editable="false"
            format="yyyy-MM-dd HH:mm"
            placeholder="请选择结束时间"
            prefix-icon="el-icon-time"
            style="width: 214px"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>
      </CSSelect>
      <CSSelect>
        <select v-model="conditions.alreadyPayState">
          <option value="">缴费状态不限</option>
          <option
              v-for="(identity,index) in invoiceStatud"
              :key="identity+index"
              :value="index"
          >
            {{ identity }}
          </option>
        </select>
      </CSSelect>
      <CSSelect>
        <select v-model="collectId">
          <option value="">公摊汇总名称不限</option>
          <option
              v-for="identity in summaryList"
              :key="identity.id"
              :value="identity.id"
          >
            {{ identity.equallySharedName }}
          </option>
        </select>
      </CSSelect>
      <CSSelect>
        <select v-model="conditions.billGenerateWay">
          <option value="">账单产生方式不限</option>
          <option
              v-for="identity in production"
              :key="identity.id"
              :value="identity.id"
          >
            {{ identity.value }}
          </option>
        </select>
      </CSSelect>
      <CSSelect>
        <select v-model="conditions.modePayment">
          <option value="">支付渠道不限</option>
          <option
              v-for="(identity,index) in modePaymentList"
              :key="identity"
              :value="index"
          >
            {{ identity }}
          </option>
        </select>
      </CSSelect>
      <div
          style="
                  vertical-align: middle;
                  margin-bottom: 15px;
                  display: inline-block;
                "
      >
        <input
            v-model="conditions.search"
            class="inputSelect"
            placeholder="搜索账单号/业主/租客"
            type="text"
        />
      </div>
      <button class="btn btn-primary btn-sm" type="button" @click="query()">
        查询
      </button>
    </div>


    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template
            v-slot:header
        >
          <div style="padding-top: 30px;margin-left: 30px;display: inline-block;float: left">
            账单金额总计：{{ billAmount.billAmountSum }}元
          </div>
          <div style="padding-top: 30px;margin-left: 30px;display: inline-block;float: left">
            已缴费金额总计：{{ billAmount.billAmountPaySum }}元
          </div>
          <div style="padding-top: 30px;margin-left: 30px;display: inline-block;float: left">
            未缴费金额总计：{{ billAmount.billAmountNoPaySum }}元
          </div>
          <div class="table-header-panel text-right">
            <button
                class="btn btn-primary sticky-right"
                v-on:click="_openEditRoomModel()"
            >
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              添加电费公摊账单
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>账单生成时间</th>
            <th>账单号</th>
            <th>账单缴费状态</th>
            <th>账单金额（元）</th>
            <th>公摊规则</th>
            <th>账单产生方式</th>
            <th>所属公摊汇总</th>
            <th>计费起止日期</th>
            <th>水电公摊承担方</th>
            <th>房间号</th>
            <th>水电公摊承担面积（m²）</th>
            <th>支付渠道</th>
            <th>缴费人</th>
            <th>修改人</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-if="list&&list.length > 0">
            <tr v-for="item in list" :key="item.id">
              <td style="min-width: 125px">{{ item.billCreateTime }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.billState == 1 ? '作废' : invoiceStatus[item.alreadyPayState] }}</td>
              <td>{{ item.billAmount }}</td>
              <td><span class="allow-click" @click="checkoutRule(item)">查看</span></td>
              <td>{{ billGenerateWayList[+item.billGenerateWay] }}</td>
              <td>{{ item.equallySharedCollectName }}</td>
              <td>{{ item.billStartDate + '~' + item.billEndDate }}</td>
              <td><span class="allow-click" @click="checkoutQi(item)">{{ item.assumeLesseeName }}</span></td>
              <td>{{ item.buildingName+item.floor+'层'+item.roomNo }}</td>
              <td style="min-width: 170px">{{ item.roomSpace }}</td>
              <td><span v-if="modePaymentList[+item.modePayment]" class="allow-click"
                        @click="checkoutImage(item)">{{ modePaymentList[+item.modePayment] }}</span>
                <span v-else>-</span>
              </td>
              <td>{{ item.billPayCostUserName || '-' }}</td>
              <td>{{ item.lastModifyUserName || '-' }}</td>
              <td>
                <div class="btn-group">
                  <button
                      :class="['btn','btn-primary','dropdown-toggle',{
                                            'disableBtn':item.alreadyPayState == 1 || item.alreadyPayState == 3|| item.billState == 1
                                        }]"
                      :disabled="(item.alreadyPayState == 1 || item.alreadyPayState == 3 || item.billState == 1)"
                      aria-expanded="false"
                      aria-haspopup="true"
                      data-toggle="dropdown"
                      type="button"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu" style="min-width: 118px; width: 118px;">
                    <li v-if="item.alreadyPayState != 0" @click="issueCard(item)">
                      <a style="width: 100%;">线下付款</a>
                    </li>
                    <li @click="_openEditRoomModel(item)">
                      <a style="width: 100%;">修改</a>
                    </li>
                    <li v-if="(item.billGenerateWay == 0) ||((item.alreadyPayState != 0)&& item.billGenerateWay == 1)"
                        @click="toVoid(item)">
                      <a style="width: 100%;">作废</a>
                    </li>
                    <li v-if="(item.billGenerateWay == 1&&item.alreadyPayState == 0)"
                        @click="deleteBill(item)">
                      <a style="width: 100%;">删除</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr v-if="isLoading">
              <td :colspan="15" class="wrapper-loading">
                <div style="text-align: center">加载中
                </div>
                <div class="loading loading1"></div>
                <div class="loading loading2"></div>
                <div class="loading loading3"></div>
                <div class="loading loading4"></div>
                <div class="loading loading5"></div>
              </td>
            </tr>
            <tr v-else>
              <td :colspan="tdLength">
                <div style="text-align: center">暂无数据</div>
              </td>
            </tr>
          </template>


        </template>
      </CSTable>
      <Pagination></Pagination>
      <!--            //添加账单-->
      <AddChargeCS :dialogTitle="dialogTitle" :isElectricty='true'></AddChargeCS>
      <!--                线下支付-->
      <OfflinePaymentCS></OfflinePaymentCS>
      <!--            查看规则-->
      <PoolingRules :isElectricty='true'></PoolingRules>
      <CSDialog
          :dialog-show-confirm-btn="false"
          :dialog-visible="dealResultRules"
          class="tenant-detail-dialog"
          dialog-cancel-btn-text="关闭"
          dialog-header-class="alert-bg"
          dialog-title="水电公摊承担方"
          dialog-width='630px'
          @onClose="dealResultRules = false"
      >
        <template v-slot:dialog-content>
          <div class="wrapper">
            <div class="from-item">
              <div class="from-item-top">企业/个人名称</div>
              <div class="from-item-bottom">
                <div class="item-bottom">
                  <div class="item-from">{{ enterprise.companyName }}</div>
                </div>
              </div>
            </div>
            <div class="from-item">
              <div class="from-item-top">合同时间</div>
              <div class="from-item-bottom">
                <div class="item-bottom">
                  <div class="item-from">{{ enterprise.startDate }}至{{ enterprise.endDate }}</div>
                </div>
              </div>
            </div>
            <div class="from-item">
              <div class="from-item-top">对接人</div>
              <div class="from-item-bottom">
                <div class="item-bottom">
                  <div class="item-from">{{ enterprise.deputyName }}</div>
                </div>
              </div>
            </div>
            <div class="from-item">
              <div class="from-item-top">对接人手机号</div>
              <div class="from-item-bottom">
                <div class="item-bottom">
                  <div class="item-from">{{ enterprise.deputyPhone }}</div>
                </div>
              </div>
            </div>
          </div>

        </template>
      </CSDialog>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  billAmountStatisticsUrl,
  deleteEquallySharedBillUrl,
  queryEquallySharedBillUrl,
  queryEquallySharedCollectUrl,
  queryLesseeInfoByIdWaUrl
} from '@/requestUrl';
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination";
import CSTable from "@/components/common/CSTable";
import AddChargeCS from "@/views/WaterElectricityMeter/PoolBill/Bill/AddChargeCS";
import OfflinePaymentCS from "@/views/WaterElectricityMeter/PoolBill/Bill/OfflinePaymentCS";
import PoolingRules from "@/views/WaterElectricityMeter/PoolBill/Bill/PoolingRules";

export default {
  name: "electricityFeesBill",
  components: {
    CSDialog,
    CSSelect,
    CSTable,
    AddChargeCS,
    OfflinePaymentCS,
    PoolingRules,
    Pagination
  },
  data() {
    return {
      isLoading: true,
      pageNo: 1,
      collectName: null,
      collectId: '',
      summaryList: "",
      billAmount: '',
      enterprise: {},
      dealResultRules: false,
      dialogTitle: '',
      billGenerateWayList: ['批量生成', '手动添加'],
      list: [],
      filterHeight: 0,
      conditions: {
        regionCode: this.$vc.getCurrentRegion().code,
        startDate: dayjs().subtract(1, "month").format("YYYY-MM-DD" + ' 00:00'),
        endDate: dayjs().subtract(0, 'day').format("YYYY-MM-DD" + ' 23:59'),
        alreadyPayState: '',//缴费状态
        billGenerateWay: '',//账单生产方式
        modePayment: '',//支付渠道
        search: '',
        type: 2
      },
      //缴费状态
      invoiceStatus: [
        '未下发', '已缴费', '未缴费', '已缴费'
      ],
      invoiceStatud: [
        '未下发', '已缴费', '未缴费'
      ],
      //账单生产方式
      production: [
        {
          id: 0,
          value: '批量生成'
        },
        {
          id: 1,
          value: '手动添加'
        },
      ],
      //支付渠道
      modePaymentList: [
        '现金支付', '银行转账', '支付宝支付', '微信支付'
      ]
    }
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.queryUserListDown);
  },
  destroyed() {
    window.removeEventListener("keydown", this.queryUserListDown);
  },
  methods: {
    querySummaryList(pageNo = 1, pageSize = 99999999) {
      return this.$fly.post(queryEquallySharedCollectUrl, {
        collectName: "",
        createUserId: "",
        endDate: "",
        regionCode: this.$vc.getCurrentRegion().code,
        startDate: "",
        type: 2,
        pageNum: pageNo,
        pageSize
      }).then(res => {
        if (res.code != 0) {
          return
        }
        this.$vc.emit("loading", true);
        let arrList = res.data.list;
        this.summaryList = arrList;
      })
    },
    //删除账单
    deleteBill(item) {
      this.$CSDialog.confirm({
        title: '提示',
        message: '确定删除吗?',
        onConfirm: () => {
          this.$fly.delete(deleteEquallySharedBillUrl, {
            billState: 2,
            id: item.id,
            userName: this.$vc.getCurrentStaffInfo().name,//用户名称
            userId: this.$vc.getCurrentStaffInfo().userId//用户ID
          }).then(res => {
            if (res.code != 0) {
              return
            }
            this.$vc.toast(`删除成功`);
            this.query();
            this.$CSDialog.instance.closeDialog();
          })

        }
      })
    },
    //查看图片
    checkoutImage(item) {
      let payOrder = item.payOrder;
      this.$CSDialog.alert({
        title: '付款凭证',
        width: "400px",
        messageHtml: `
                <div class="comment-panel text-center">
                    <div class="item text-center">
                        <div style="max-width: 100%;">
                            <img src="${payOrder}" style="width: 100%">
                        </div>
                    </div>
                </div>
                `,
      });
    },
    async checkoutQi(item) {
      this.enterprise = await this.$fly
          .get(queryLesseeInfoByIdWaUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            lesseeId: item.assumeLesseeId
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            return res.data;
          });
      this.dealResultRules = true;
    },
    //查看规则
    checkoutRule(item) {
      this.$vc.emit('polingRulesCsD', {...item})
    },
    toVoid(item) {
      this.$CSDialog.confirm({
        title: '提示',
        message: '确定作废吗?',
        onConfirm: () => {
          this.$fly.delete(deleteEquallySharedBillUrl, {
            billState: 1,
            id: item.id,
            userName: this.$vc.getCurrentStaffInfo().name,//用户名称
            userId: this.$vc.getCurrentStaffInfo().userId//用户ID
          }).then(res => {
            if (res.code != 0) {
              return
            }
            this.$vc.toast(`作废成功`);
            this.query(this.pageNo);
            this.$CSDialog.instance.closeDialog();
          })

        }
      })

    },
    //线下付款
    issueCard(item) {

      this.$vc.emit('addRoomVisible', {...item})
    },
    queryUserListDown(e) {
      if (e.keyCode == 13) {
        this.query();
      }
    },
    //添加账单
    _openEditRoomModel(item) {
      if (item) {
        this.dialogTitle = '修改电费公摊账单'
      } else {
        this.dialogTitle = '添加电费公摊账单'
      }
      this.$vc.emit('addRoomVisibleHy', item)
    },
    //查询
    query(pageNum = this.pageNo, pageSize = 10) {
      this.isLoading = true;
      this.$fly.post(billAmountStatisticsUrl, {
        ...this.conditions,
        collectName: this.collectName,
        collectId: this.collectId ? [this.collectId] : [],
        pageNum,
        pageSize
      }).then(res => {
        if (res.code != 0) {
          return
        }
        this.billAmount = res.data;
      })
      this.$fly.post(queryEquallySharedBillUrl, {
        ...this.conditions,
        collectName: this.collectName,
        collectId: this.collectId ? [this.collectId] : [],
        pageNum,
        pageSize
      }).then(res => {
        if (res.code != 0) {
          return
        }
        if (pageNum == 1) {
          this.$vc.emit(this.$route.path, "pagination", "init", {
            total: res.data.total,
            currentPage: pageNum,
            pageSize,
          });
        }
        this.isLoading = false;
        this.list = res.data.list;
      })
    }
  },
  async created() {
    this.collectId = "";
    await this.querySummaryList();
    if (this.$route.params.invoiceStatus) {
      this.conditions.alreadyPayState = this.$route.params.invoiceStatus;
    }
    if (this.$route.params.collectId) {
      this.collectId = this.$route.params.collectId;
      this.$route.params.collectId = "";
    }

    window.addEventListener("keydown", this.queryUserListDown);
    this.query();
    this.$vc.on('queryBillWarter', () => {
      this.query();
    })
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.pageNo = pageNo;
      this.query(pageNo);
    });
  }
}
</script>

<style scoped>
.inputSelect {
  color: #000;
  width: 240px;
  height: 30px;
  padding-left: 10px;
  border-radius: 4px;
  border: 1px solid #979797;
  margin-right: 20px;
}

.wrapper {
  padding: 30px;
}

.from-item {
  font-size: 24px;
  color: #000000;
  display: flex;
  align-items: top;
  text-align: right;
  margin-bottom: 22px;
}

.from-item-top {
  width: 220px;
  margin-right: 40px;

}

.item-bottom {
  display: flex;
}

.disableBtn {
  background: rgba(204, 204, 204, 1);
  color: #FFFFFF;
  border: none;
  cursor: not-allowed;
}

.loading {
  animation: identifier 2s linear 0s infinite;
  width: 1px;
  background-color: black;
  height: 15px;
  margin-left: 5px;
}

.loading2 {
  animation: identifier 2s linear 0.1s infinite;
}

.loading3 {
  animation: identifier 2s linear 0.2s infinite;
}

.loading4 {
  animation: identifier 2s linear 0.3s infinite;
}

.loading5 {
  animation: identifier 2s linear 0.4s infinite;
}

.wrapper-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes identifier {
  0% {
    height: 15px;
  }
  25% {
    height: 0px;
  }
  50% {
    height: 15px;
  }
  75% {
    height: 0px;
  }
  100% {
    height: 15px;
  }
}

.item-from {
  margin-right: 20px;
}
</style>
